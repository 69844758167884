@import "~@/assets/scss/variables.scss";
























































































































































































































































































































.caret-right {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.caret-down {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 0.95em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(195, 195, 195);
}

.sensor-gateway-warning {
  color: #c5970b;
  font-size: 0.8rem;
  background-color: #fdf7e4;
}

.sensor-gateway-info {
  color: rgb(105, 105, 105);
  font-size: 0.8rem;
  background-color: #f5f5f5;
}

.info-icon {
  cursor: pointer;
  color: #aaa;
}
.info-icon:hover {
  color: #888;
}
